<template>
  <div class="box">

    <div class="mb-4 relative">
      <!--  -->
      <div class="accordion-item col-span-12 text-xs">
        <div id="faq-accordion-content" class="border-b border-gray-200 dark:border-dark-5 accordion-header flex justify-between col-span-2">
          <button
            class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#faq-accordion-collapse-1"
            aria-expanded="true"
            aria-controls="faq-accordion-collapse-1"
          >
            <div class="flex flex-col sm:flex-row items-center p-4">
              <h4 class="mr-auto capitalize text-base font-medium font-bold">Reporte General Dispositivos</h4>
            </div>
          </button>
        </div>
        <div id="faq-accordion-collapse" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content" data-bs-parent="#faq-accordion">
          <div class="accordion-body text-gray-700 m-4 dark:text-gray-600 leading-relaxed">

            <!-- Filtros -->
            <Filters v-model="filters" @search="onFilter" @downloadExcel="handleDownloadExcel" @info="onInfo"/>

            <!-- Tabla -->
            <div class="col-span-12 lg:col-span-12 pb-4">
              <DataTable
                :value="dataDispositivos"
                showGridlines
                responsiveLayout="stack"
                breakpoint="1260px"
                :loading="loading"
                class="p-datatable-sm text-xs"
                :lazy="true"
                :paginator="true"
                :rows="10"
                :totalRecords="totalRecords"
                @page="onPage($event)"
                @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5,10,20,50,100]"
                currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
              >
                <ColumnGroup type="header">
                  <Row>
                    <Column header="Departamento" :rowspan="3" field="departamentoNombre" :sortable="true" />
                    <Column header="Municipio" :rowspan="3" field="ciudadNombre" :sortable="true" />
                    <Column header="Ubicación" :rowspan="3" field="sedeNombre" :sortable="true" />
                    <Column header="Dispositivo" :rowspan="3" field="nombre" :sortable="true" />
                    <Column header="Serial" :rowspan="3" field="serial" :sortable="true" />
                  </Row>
                  <Row>
                    <Column :colspan="3">
                      <template #header>
                        <div class="flex justify-center w-full">Temperatura Interna</div>
                      </template>
                    </Column>
                    <Column :colspan="3">
                      <template #header>
                        <div class="flex justify-center w-full">Temperatura Externa</div>
                      </template>
                    </Column>
                    <Column :colspan="3">
                      <template #header>
                        <div class="flex justify-center w-full">Humedad</div>
                      </template>
                    </Column>
                  </Row>
                  <Row>
                    <Column :header="isMobile ? 'Temp. Interna Max/Min' : 'Max/Min'"></Column>
                    <Column :header="isMobile ? 'Temp. Interna Valor' : 'Temp.'" field="sensorTempInternaValor" :sortable="true" ></Column>
                    <Column :header="isMobile ? 'Temp. Interna Fecha' : 'Fecha'" field="sensorTempInternaFechaDispo" :sortable="true" ></Column>
                    <Column :header="isMobile ? 'Temp. Externa Max/Min' : 'Max/Min'"></Column>
                    <Column :header="isMobile ? 'Temp. Externa Valor' : 'Temp.'" field="sensorTempExternaValor" :sortable="true" ></Column>
                    <Column :header="isMobile ? 'Temp. Externa Fecha' : 'Fecha'" field="sensorTempExternaFechaDispo" :sortable="true" ></Column>
                    <Column :header="isMobile ? 'Humedad Max/Min' : 'Max/Min'"></Column>
                    <Column :header="isMobile ? 'Humedad Valor' : 'Porcentaje'" field="sensorHumedadValor" :sortable="true" ></Column>
                    <Column :header="isMobile ? 'Humedad Fecha' : 'Fecha'" field="sensorHumedadFechaDispo" :sortable="true" ></Column>
                  </Row>
                </ColumnGroup>
                <Column header="Departamento" field="departamentoNombre" :sortable="true"></Column>
                <Column header="Municipio" field="ciudadNombre" :sortable="true"></Column>
                <Column header="Ubicación" field="sedeNombre" :sortable="true"></Column>
                <Column header="Dispositivo" field="nombre" :sortable="true"></Column>
                <Column header="Serial" field="serial" :sortable="true"></Column>
                <Column :header="isMobile ? 'Temp. Interna Max/Min' : 'Max/Min'" field="" style="background: rgba(226, 239, 218, 0.5);">
                  <template #body="{data}">
                    <span>{{ data.sensorTemperaturaInternaRangoMaximo }}°C/{{ data.sensorTemperaturaInternaRangoMinimo }}°C</span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Temp. Interna Valor' : 'Temp.'" field="sensorTempInternaValor" :sortable="true" style="background: rgba(226, 239, 218, 0.5);">
                  <template #body="{data}">
                    <span v-if="data.sensorTempInternaValor">
                      {{data.sensorTempInternaValor}}°C
                    </span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Temp. Interna Fecha' : 'Fecha'" field="sensorTempInternaFechaDispo" :sortable="true" style="background: rgba(226, 239, 218, 0.5);">
                  <template #body="{data}">
                    <span>
                      {{ data.sensorTempInternaFechaDispo
                        ?
                        $h.formatDate(
                          data.sensorTempInternaFechaDispo || "",
                          "DD-MM-YYYY HH:mm:ss"
                        )
                        : "Sin fecha"
                      }}
                    </span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Temp. Externa Max/Min' : 'Max/Min'" field="" style="background: rgba(255, 242, 204, 0.5);">
                  <template #body="{data}">
                    <span>{{ data.sensorTemperaturaExternaRangoMaximo }}°C/{{ data.sensorTemperaturaExternaRangoMinimo }}°C</span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Temp. Externa Valor' : 'Temp.'" field="sensorTempExternaValor" :sortable="true" style="background: rgba(255, 242, 204, 0.5);">
                  <template #body="{data}">
                    <span v-if="data.sensorTempExternaValor">
                      {{data.sensorTempExternaValor}}°C
                    </span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Temp. Externa Fecha' : 'Fecha'" field="sensorTempExternaFechaDispo" :sortable="true" style="background: rgba(255, 242, 204, 0.5);">
                  <template #body="{data}">
                    <span>
                      {{ data.sensorTempExternaFechaDispo
                        ?
                        $h.formatDate(
                          data.sensorTempExternaFechaDispo || "",
                          "DD-MM-YYYY HH:mm:ss"
                        )
                        : "Sin fecha"
                      }}
                    </span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Humedad Max/Min' : 'Max/Min'" field="" style="background: rgba(221, 235, 247, 0.5);">
                  <template #body="{data}">
                    <span>{{ data.sensorHumedadRangoMaximo }}%/{{ data.sensorHumedadRangoMinimo }}%</span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Humedad Valor' : 'Porcentaje'" field="sensorHumedadValor" :sortable="true" style="background: rgba(221, 235, 247, 0.5);">
                  <template #body="{data}">
                    <span v-if="data.sensorHumedadValor">
                      {{data.sensorHumedadValor}}%
                    </span>
                  </template>
                </Column>
                <Column :header="isMobile ? 'Humedad Fecha' : 'Fecha'" field="sensorHumedadFechaDispo" :sortable="true" style="background: rgba(221, 235, 247, 0.5);">
                  <template #body="{data}">
                    <span>
                      {{ data.sensorHumedadFechaDispo
                        ?
                        $h.formatDate(
                          data.sensorHumedadFechaDispo || "",
                          "DD-MM-YYYY HH:mm:ss"
                        )
                        : "Sin fecha"
                      }}
                    </span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { apiInfoDispositivos } from '../../services/dispositivos.service'
import { ref, onMounted, defineAsyncComponent, computed } from 'vue'
import { apiDownloadExcelDispositivos } from '../../services/xls.service'
import { useStore } from '../../../../../store'
import { messageConfirm, success } from '../../../../../libs/mensajes'

export default {
  name: 'SensproReportsIndex',
  components: {
    Filters: defineAsyncComponent(() => import('./filters/index.vue'))
  },
  setup () {
    const store = useStore()
    const dataDispositivos = ref([])
    const totalRecords = ref(0)
    const lazyParams = ref({})
    const loading = ref(true)
    const sortField = ref('nombre')
    const sortOrder = ref(1)
    const currentPage = ref(1)
    const filters = ref({
      departamentoId: '',
      ciudadId: '',
      querySearch: ''
    })
    const infoListDepartamentos = ref([])
    const infoListCiudades = ref([])

    /* Evento de paginacion */
    const onPage = (event) => {
      lazyParams.value = event
      fetchInfoDispositivos(event, 'page', filters)
    }

    /* Evento de ordenamiento por columna de la tabla */
    const onSort = (event) => {
      lazyParams.value = event
      fetchInfoDispositivos(event, 'sort', filters)
    }

    /* Evento para los filtros */
    const onFilter = (event) => {
      fetchInfoDispositivos(event, 'filter', filters)
    }

    /* Obtener la información de los dispositivos */
    const fetchInfoDispositivos = async (event = false, typeEvent, filters) => {
      let queryParams = {}
      if (!event) {
        queryParams = {
          currentPage: 1,
          perPage: 10,
          sortField: 'nombre',
          sortOrder: 1
        }
      } else {
        if (typeEvent === 'sort') {
          sortField.value = event.sortField
          sortOrder.value = event.sortOrder
        }
        queryParams = {
          currentPage: `${typeEvent === 'sort' || typeEvent === 'filter' ? currentPage.value : event.page + 1}`,
          perPage: event.rows || 10,
          sortField: sortField.value,
          sortOrder: sortOrder.value,
          departamentoId: filters.value.departamentoId,
          ciudadId: filters.value.ciudadId,
          querySearch: filters.value.querySearch
        }
      }
      loading.value = true
      return apiInfoDispositivos(queryParams).then(({ status, data: response }) => {
        dataDispositivos.value = response.data
        totalRecords.value = response.total
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.error(err.message)
      })
    }

    const onInfo = (value) => {
      infoListDepartamentos.value = value.departamentos
      infoListCiudades.value = value.ciudades
    }

    /* Descargar excel */
    const handleDownloadExcel = (event) => {
      const token = store.getters['auth/getToken']
      const queryParams = {
        departamentoId: event.filters.departamentoId ? parseInt(event.filters.departamentoId) : null,
        ciudadId: event.filters.ciudadId ? parseInt(event.filters.ciudadId) : null,
        querySearch: event.filters.querySearch ? event.filters.querySearch : null,
        token
      }

      const filtroDep = infoListDepartamentos.value.find(item => item.id === parseInt(event.filters.departamentoId))
      const filtroCiu = infoListCiudades.value.find(item => item.id === parseInt(event.filters.ciudadId))
      const filtroDepartamento = filtroDep ? filtroDep.name : 'Todos'
      const filtroCiudad = filtroCiu ? filtroCiu.name : 'Todos'

      messageConfirm('', 'Exportar a excel', 'warning',
        `
          <span class="text-sm">Se exportará a excel con los siguientes filtros aplicados: Departamento: <strong>${filtroDepartamento}</strong>, Ciudad: <strong>${filtroCiudad}</strong></span>
        `
      ).then(({ isConfirmed }) => {
        if (isConfirmed) {
          apiDownloadExcelDispositivos(queryParams).then(res => {
            window.open(res, '_blank')
            success('Se descargo el archivo correctamente', 'Descarga exitosa')
          }).catch(err => {
            console.error(err)
          })
        }
      })
    }

    /* Es mobile */
    const isMobile = computed(() => window.innerWidth <= 760)

    /* Hook */
    onMounted(() => {
      fetchInfoDispositivos()
    })

    return {
      onPage,
      onSort,
      onFilter,
      dataDispositivos,
      totalRecords,
      loading,
      filters,
      handleDownloadExcel,
      onInfo,
      isMobile
    }
  }
}
</script>

<style scoped>

</style>
