import http from '../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_SENSPRO}`

const apiDownloadExcelDispositivos = async (query) => {
  const url = `${api}/reports/export/dispositivos`
  return http.getUri({ url, params: query })
}

export {
  apiDownloadExcelDispositivos
}
